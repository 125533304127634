import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TourFrame from "../components/TourFrame"

import ServiciosLista from '../components/ServiciosLista'

import BannerInterior from '../components/BannerInterior'
import poster from '../images/banner-servicios.jpg'

import CardServicio from '../components/CardServicio'
import st1 from '../images/thumb-renta-salon.jpg'
import st2 from '../images/thumb-iluminacion.jpg'
import st3 from '../images/thumb-arreglo-complemento.jpg'
import st4 from '../images/thumb-menu-tiempos.jpg'
import st6 from '../images/thumb-empresariales.jpg'
import st7 from '../images/thumb-posadas.jpg'

import icon1 from '../images/i-coordinacion-completa.svg'
import icon2 from '../images/i-amplia-estancia.svg'
import icon3 from '../images/i-whisky.svg'
import icon4 from '../images/i-servicio-meseros.svg'
import icon5 from '../images/i-estacionamiento.svg'
import icon6 from '../images/i-salon-civil.svg'

import CardSalon from '../components/CardSalon'
import salon1 from '../images/thumb-plaza-cataluna.jpg'
import salon2 from '../images/thumb-salon-jordi.jpg'

import CTADescargas from '../components/BannerDescargas'
import BannerAgenda from '../components/BannerAgenda'

const beneficios = [
	{
		src: icon1,
		title: 'Coordinación completa en todo su evento',
		caption: 'No incluye hostess ni asignación de mesas.'
	},
	{
		src: icon2,
		title: 'Amplia estancia para novios y quinceañeras',
		caption: ''
	},
	{
		src: icon3,
		title: 'Whisky para todos',
		caption: 'Barra libre de whisky durante todo el evento.'
	},
	{
		src: icon4,
		title: 'El mejor servicio de meseros',
		caption: 'Somos los únicos que ofrecemos 1 mesero por cada 2 mesas o 20 invitados.'
	},
	{
		src: icon5,
		title: 'Amplio estacionamiento',
		caption: 'No hagas a tus invitados buscar lugar, nosotros tenemos resuelto eso.'
	},
	{
		src: icon6,
		title: 'Boda civil',
		caption: 'Cortesía para salón civil incluye bocadillos, brindis con vino espumoso.'
	},
]



const Eventos = () => (
	<Layout>

		<SEO title="Eventos" />

		<BannerInterior
			small="Barcelona"
			title="Eventos"
			caption="Tenemos espacio para todo tipo de eventos."
			imgsrc={poster}
			textstyle="darks"
		/>

		<section className="eventos-empresariales">

			<ServiciosLista></ServiciosLista>

			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Eventos</h3>
				<p className="heading-caption">Tu Evento en Barcelona</p>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Empresariales"
							styleclass="overlay"
							btncolor="azul"
							btnLabel="Ver galería"
							link=""
							thumbnail={st6}
						>
							<strong>¿Tienes algún evento próximo?</strong> <p>Ven y cónoce nuestras instalaciones. Te sorprenderá lo que podemos hacer por las empresas, contamos con el mejor menú y con la mejor ubicación para el sector empresarial.</p>
						</CardServicio>
					</div>
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Posadas"
							styleclass="simple"
							btncolor="gold"
							btnLabel="Ver galería"
							link=""
							thumbnail={st7}
						>
							<p>Tenemos nuestras puertas abiertas para tu posada navideña. Queremos ayudarte, tenemos un excelente staff y el mejor chef para tu evento.</p>
							<ul>
								<li>Arreglo de lobby</li>
								<li>Capacidad hasta de 1000 personas</li>
								<li>Coordinador de eventos</li>
							</ul>
						</CardServicio>
					</div>
				</div>
			</div>

			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Contáctanos</h3>
				<p className="heading-caption">¿Necesitas una cotización para tu evento?</p>
				<Link to="/contacto" className="btn btn-solid azul extra-padding">Ver más</Link>
				
			</div>
		</section>
		
		<section className="nuestros-paquetes">

			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Nuestros paquetes</h3>
				<p className="heading-caption">¿Qué incluyen los paquetes?</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Renta de salón"
							styleclass="overlay"
							btncolor="azul"
							btnLabel="Ver galería"
							link=""
							thumbnail={st1}
						>
							<p>Todos los paquetes incluyen renta de salón durante 5 horas + 1 hora de cortesía antes del evento para recibir a tus invitados.</p>
						</CardServicio>
					</div>
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Iluminación"
							styleclass="simple"
							btncolor="gold"
							btnLabel="Ver galería"
							link=""
							thumbnail={st2}
						>
							<p className="text-white">Iluminación arquitectónica interior y exterior.</p>
						</CardServicio>
					</div>
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Arreglo completo del salón"
							styleclass="overlay"
							btncolor="azul"
							btnLabel="Ver galería"
							link=""
							thumbnail={st3}
						>
							<ul>
								<li>Arreglo de lobby</li>
								<li>Arreglo de mesa de honor</li>
								<li>Centros de mesa a elegir</li>
							</ul>
						</CardServicio>
					</div>
					<div className="col-md-6 mb-5">
						<CardServicio
							title="Menú de 4 tiempos "
							styleclass="overlay"
							btncolor="azul"
							btnLabel="Ver catering"
							link="/catering"
							thumbnail={st4}
						>
							<p>Menú de gala a elegir a 4 tiempos con degustación previa de platillos.</p>
						</CardServicio>
					</div>
				</div>
			</div>
		</section>

		<section className="otros-beneficios">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Cotiza tu paquete</h3>
				<p className="heading-caption">Otros beneficios</p>
			</div>
			<div className="container">
				<div className="row">
					{
						beneficios.map(function(item, i){
							return(
								<div key={i} className="item col-sm-6 col-md-4 mb-5">
									<figure>
										<span>
											<img src={item.src} alt={item.title}/>
										</span>
										<figcaption>
											<h3>{item.title}</h3>
											<p>{item.caption}</p>
										</figcaption>
									</figure>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>

		<section className="salones-block">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Instalaciones</h3>
				<p className="heading-caption">Tu boda en el salón de tus sueños</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Plaza Cataluña"
							subtitulo="Capacidad: 500 personas"
							imgsrc={salon1}
							link="/plaza-cataluna"
						/>
					</div>
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Saint Jordi"
							subtitulo="Capacidad: 300 personas"
							imgsrc={salon2}
							link="/saint-jordi"
						/>
					</div>
				</div>
			</div>
		</section>

		<TourFrame source="https://my.matterport.com/show/?m=6oHksCqAC1J"></TourFrame>

		<CTADescargas></CTADescargas>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default Eventos
