import React, {Component} from "react"
import { Link } from "gatsby"
import { SimpleImg } from 'react-simple-img'

import banner from '../images/banner-agenda.jpg'

class BannerAgenda extends Component{
	render(){
		return(
			<section className={`banner-agenda ${ this.props.paddingtop }`}>
				<div className="container">
					<div className="banner text-center">
						<div className="inner">
							<div className="heading-s1">
								<h5 className="heading-titulo">Agenda tu cita</h5>
								<p className="heading-caption">¿Estás interesado en hacer tu evento con nosotros?</p>
							</div>
							<p>Deja tus datos y uno de nuestros vendedores se comunicará contigo a la brevedad.</p>
							<Link to="/contacto" className="btn btn-solid azul">Agendar cita</Link>
						</div>
						<SimpleImg src={banner} placeholder={false} alt="Agenda tu cita" />
						{/* <img src={banner} alt="Agenda tu cita" width="1711" height="533"/> */}
					</div>
				</div>
			</section>
		)
	}
}

export default BannerAgenda
