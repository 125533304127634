import React, {Component} from "react"

import servicon1 from '../images/bar-bodas.svg'
import servicon2 from '../images/bar-posadas.svg'
import servicon3 from '../images/bar-xv.svg'
import servicon4 from '../images/bar-graduaciones.svg'
import servicon5 from '../images/bar-corporativos.svg'
import servicon6 from '../images/bar-despedidas.svg'

const servicios = [
	{
		src: servicon1,
		title: 'Bodas'
	},
	{
		src: servicon2,
		title: 'Posadas'
	},
	{
		src: servicon3,
		title: 'XV Años'
	},
	{
		src: servicon4,
		title: 'Graduaciones'
	},
	{
		src: servicon5,
		title: 'Corporativos'
	},
	{
		src: servicon6,
		title: 'Despedidas'
	},
]

class ServiciosLista extends Component{
	render(){
		return(
			<div className="servicios-lista">
				<div className="container-fluid">
					<div className="row">
						{
							servicios.map(function(item, i){
								return(
									<div key={i} className="item col-6 col-sm-4 col-md-2">
										<figure>
											<span>
												<img src={item.src} alt={item.title}/>
											</span>
											<figcaption>
												<h2>{item.title}</h2>
											</figcaption>
										</figure>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}

export default ServiciosLista
