import React, { Component } from "react"

import xv from '../downloads/xv.jpg'
import bodas from '../downloads/bodas.jpg'

class CTADescargas extends Component{
	render(){
		return(
			<section className="cta-descargas">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="heading-s1 text-center">
								<h3 className="heading-titulo">Descargas</h3>
								<p className="heading-caption">¿Te interesa descargar la información de nuestros paquetes de eventos?</p>
								<a href={bodas} download target="_blank" rel="noopener noreferrer" className="btn btn-outline mr-3 mb-3">
									<svg className="mr-2" width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.0002 6H15.0002L10.0002 11L5.00023 6H8.00023V0H12.0002V6ZM19.2708 12.46C19.2995 12.4907 19.3223 12.515 19.3382 12.532C19.8282 13.053 20.0962 13.468 19.9702 13.981L19.4092 17.055C19.2812 17.569 18.7182 17.991 18.1572 17.991H1.84523C1.28423 17.991 0.721225 17.569 0.593225 17.055L0.0322251 13.981C-0.0957749 13.468 0.173225 13.053 0.662225 12.532C0.691029 12.5014 0.74224 12.4468 0.810201 12.3744C1.23772 11.9186 2.32809 10.7562 2.67323 10.418C2.93923 10.159 3.31822 10 3.71522 10H5.47123L2.40823 12.994H5.95223C6.05323 12.994 6.14622 13.046 6.19222 13.127L7.00822 15H12.9922L13.8082 13.127C13.8542 13.046 13.9462 12.994 14.0482 12.994H17.5922L14.5282 10H16.2852C16.6832 10 17.0622 10.159 17.3272 10.418C17.6969 10.7793 18.9214 12.0869 19.2708 12.46Z" fill="currentcolor"/></svg>
									Paquete de Bodas 2019
								</a>
								<a href={xv} download target="_blank" rel="noopener noreferrer" className="btn btn-outline mb-3">
									<svg className="mr-2" width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.0002 6H15.0002L10.0002 11L5.00023 6H8.00023V0H12.0002V6ZM19.2708 12.46C19.2995 12.4907 19.3223 12.515 19.3382 12.532C19.8282 13.053 20.0962 13.468 19.9702 13.981L19.4092 17.055C19.2812 17.569 18.7182 17.991 18.1572 17.991H1.84523C1.28423 17.991 0.721225 17.569 0.593225 17.055L0.0322251 13.981C-0.0957749 13.468 0.173225 13.053 0.662225 12.532C0.691029 12.5014 0.74224 12.4468 0.810201 12.3744C1.23772 11.9186 2.32809 10.7562 2.67323 10.418C2.93923 10.159 3.31822 10 3.71522 10H5.47123L2.40823 12.994H5.95223C6.05323 12.994 6.14622 13.046 6.19222 13.127L7.00822 15H12.9922L13.8082 13.127C13.8542 13.046 13.9462 12.994 14.0482 12.994H17.5922L14.5282 10H16.2852C16.6832 10 17.0622 10.159 17.3272 10.418C17.6969 10.7793 18.9214 12.0869 19.2708 12.46Z" fill="currentcolor"/></svg>
									Paquete de XV Años 2019
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default CTADescargas
