import React, {Component} from "react"
import { Link } from "gatsby"
import { SimpleImg } from 'react-simple-img'

class CardSalon extends Component{
	render(){
		return(
			<div className={`card-servicio ${this.props.styleclass}`}>
				<div className="inner">
					<h2 className="title">{this.props.title}</h2>
					{this.props.children}
					{ this.props.link ? <Link className={`btn btn-solid ${this.props.btncolor}`} to={this.props.link}>{this.props.btnLabel}</Link> : null  }
				</div>
				<SimpleImg className="thumb" src={this.props.thumbnail} placeholder={false} alt={this.props.title} />
			</div>
		)
	}
}

export default CardSalon
