import React, {Component} from "react"
import { Link } from "gatsby"
import { SimpleImg } from 'react-simple-img'

class CardSalon extends Component{
	render(){
		return(
			<div className="card-salon mb-5">
				<figure>
					<SimpleImg src={this.props.imgsrc} alt={this.props.titulo} placeholder={false} />
					{/* <img src={this.props.imgsrc} alt={this.props.titulo}/> */}
					<figcaption>
						<span>
							<h2>{this.props.titulo}</h2>
							<small>{this.props.subtitulo}</small>
						</span>
					</figcaption>
				</figure>
				<div className="text-center">
					<Link className="btn btn-solid azul" to={this.props.link}>Ver Salón</Link>
				</div>
			</div>
		)
	}
}

export default CardSalon
